<script>
	import Layout from "@/router/layouts/main";
	import appConfig from "@/app.config";
	import PageHeader from "@/components/page-header";
	import axios from "axios";
	import Swal from "sweetalert2"
	import Fuse from 'fuse.js'
	/**
	 * Dashboard Component
	 */
	export default {
		page: {
			title: "Konfigurasi KBLI",
			meta: [
				{
					name: "konfigurasi-kbli",
					content: appConfig.description,
				},
			],

		},
		components: {
			Layout,
			PageHeader,
		},
		data() {
			return {
				title: "Konfigurasi KBLI",
				items: [
					{
						text: "Master",
						href: "/",
					},
					{
						text: "Konfigurasi KBLI",
						active: true,
					},
				],
				// 

				axiosCatchError: null,
				
				mp_id: this.$route.params.mp_id,
				// Componen v-select
				data_perizinan: [],
				master_kbli: [],
				
				row_konfig_kbli: [],
			};
		},
		mounted() {
			// Load data awal banget
			let self = this;

			Swal.fire({
				title: '<i class="fas fa-spinner fa-spin"></i>',
				text: "Loading...",
				showConfirmButton: false,
			});
			// Get Master KBLI
			axios.get(process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/all-kbli", {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            }).then((response) => {
				this.master_kbli = response.data.data.referensi;
				
			})
			.catch(e => {
				this.axiosCatchError = e.response.data.data
				Swal.close()
			})
			
			// get data berdasarkan id
			axios.get(process.env.VUE_APP_BACKEND_URL_VERSION + "master/perizinan/get-konfig-kbli", {
                params:{
					mp_id: self.mp_id
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            }).then(function (response) {
				console.log(response);
				var response_data = response.data;
				var response_data_fix = response_data.data[0];
				if(response_data.meta.code == 200){
					self.data_perizinan = response_data_fix;
					self.row_konfig_kbli = response_data_fix.konfig_kbli;
					Swal.close();
				}else{
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: response_data_fix.data.message
					});
				}
			}).catch(e => {
				this.axiosCatchError = e.response.data.data
				Swal.close()
			})

		},
		methods:{
			addRowKBLI(){
				this.row_konfig_kbli.push({
					mkk_id: null,
					kbli: null,
				});
			},
			removeKBLI(key_deleted){
				this.row_konfig_kbli.splice(key_deleted,  1)
			},
			searchKBLI(options, search) {
				// console.log(search);
				// console.log(options);
				const fuse = new Fuse(options, {
					keys: ['mk_kode', 'mk_nama'],
					shouldSort: true,
				})
				return search.length ? fuse.search(search).map(({ item }) => item) : fuse.list
			},
			StoreData(){
				let self = this;
				Swal.fire({
					title: '<i class="fas fa-spinner fa-spin"></i>',
					text: "Loading...",
					showConfirmButton: false,
				});
				axios.post(process.env.VUE_APP_BACKEND_URL_VERSION + "master/perizinan/store-konfig-kbli", {
						perizinan_id: self.data_perizinan.mp_id,
						row_konfig_kbli: JSON.stringify(self.row_konfig_kbli)
					},
					{headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    }
				})
				.then(function (response) {
                    console.log(response)
                    let timerInterval;
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Halaman akan di muat ulang.",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                        willClose: () => {
                            clearInterval(timerInterval);
                        },
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            // self.$router.push({ name: "all-sub-perizinan" });
							window.location.reload();
						}
                    });
                }).catch(e => {
                    this.axiosCatchError = e.response.data.data
                    Swal.close();
                })
			}
		}
	};
</script>

<template>
	<Layout>
		<PageHeader :title="title" :items="items" />
		<div class="row">
			<div class="col-lg-12">
				<div class="card">
					<b-form class="p-2" @submit.prevent="StoreData" style="z-index: auto;">
						<div class="card-body">
							<div class="row">
								<div class="col-md-6">
									<div class="card-title">{{data_perizinan.mp_nama}}</div>
								</div>
								<div class="col-md-6">
									<div class="text-end"></div>
								</div>
								<div class="col-md-12">
									<div v-if="axiosCatchError">
										<div class="alert alert-danger" v-if="typeof axiosCatchError === 'string' || axiosCatchError instanceof String">
										{{axiosCatchError}}
										</div>
										<div class="alert alert-danger" role="alert" v-else>
											<div v-for="(errorArray, idx) in axiosCatchError" :key="idx">
												<div v-for="(allErrors, idx) in errorArray" :key="idx">
													<span class="text-danger">{{ allErrors[0]}} </span>
												</div>
											</div>
										</div>
									</div>
									<table class="table mb-0 table-bordered table-condensed table-hover">
										<thead class="table-info">
											<tr>
												<th>No</th>
												<th>KBLI</th>
												<th style="width: 50px;">
													<div class="btn btn-success btn-sm" v-on:click="addRowKBLI()"><i class="bx bx-plus"></i></div>
												</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(value_konfig_kbli, key_konfig_kbli) in row_konfig_kbli" :key="key_konfig_kbli" >
												<td style="width: 15px;">{{key_konfig_kbli+1}}</td>
												<td>
													<v-select 
														:options="master_kbli" 
														label="mk_nama" 
														v-model="value_konfig_kbli.kbli"
														placeholder="Pilih KBLI"
														:filter="searchKBLI"
													>
														<template v-slot:option="list_value_konfig_kbli">
															{{ list_value_konfig_kbli.mk_kode }} - {{ list_value_konfig_kbli.mk_nama }}
														</template>
														<template #selected-option="list_value_konfig_kbli">
															{{ list_value_konfig_kbli.mk_kode }} - {{ list_value_konfig_kbli.mk_nama }}
														</template>
													</v-select>
												</td>
												<td>
													<div class="btn btn-danger btn-sm" v-on:click="removeKBLI(key_konfig_kbli)"><i class="bx bx-minus"></i></div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
						<div class="card-footer">
                            <div class="text-end">
                                <b-button type="submit" variant="primary"><i class="fa fa-save"></i> Simpan</b-button>
                            </div>
                        </div>
                    </b-form>
				</div>
			</div>
		</div>
	</Layout>
</template>